import React from "react";

//Components
import SubEvents from "../components/SubEvents";
import SEO from "../components/seo"

//Categories
import AllGenresList from "../components/CategoryViews/AllGenresList";
import TodayTomorrowList from "../components/CategoryViews/TodayTomorrowList";

//Layout
import Main from "../components/Layout/Main";
import FlexContainer from "../components/Layout/FlexContainer";
import FlexItem from "../components/Layout/FlexItem";
import Card from "../components/Layout/Card";

const Events = ({ pageContext }) => {
  pageContext.data = {}
  pageContext.data.desc_event = pageContext.subeventsArray[0].node.data.genre_name_from_genre_subevent + " event information and schedule for all Zrce festivals, clubs and genres"
  pageContext.data.name_event = "Zrce " + pageContext.subeventsArray[0].node.data.genre_name_from_genre_subevent + " events"

  return (
    <section>
      <SEO context={pageContext} />
      <Main>
        <FlexContainer>
          <FlexItem>
            {pageContext.subeventsArray[0] ? (
              <React.Fragment>
                <Card>
                  <h1>{pageContext.subeventsArray[0].node.data.genre_name_from_genre_subevent}</h1>
                </Card>
                <SubEvents subeventNodes={pageContext.subeventsArray} />
              </React.Fragment>
            ) : (
              <p>no sub events yet</p>
            )} 
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <TodayTomorrowList />
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <AllGenresList />
          </FlexItem>
        </FlexContainer>
      </Main>
    </section>
  );
};

export default Events;
