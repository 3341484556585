import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";

const AllEventLinks = styled.div`
  background-color: #000000;
  padding-top: 16px;
  padding-bottom: 16px;

  h2 {
    padding: 0px 16px;
    color: white;
  }

  ul {
    padding: 0px 16px;
    list-style-type: none;
    line-height: 200%;
  }

  a {
    text-decoration: none;
    color: #52c2f0;
  }
`

const AllGenresList = () => {
  const genres = useStaticQuery(
    graphql`
      query {
        allAirtable(filter: { table: { eq: "Genres" } } ) {
          edges {
            node {
              id
              data {
                genre_name
                url_slug_genre
              }
            }
          }
        }
      }
    `)
  
  return (
    <AllEventLinks>
    <h2>
      Genres
    </h2>
    <ul>
      {genres.allAirtable.edges.map((genre) => {
        //Just if there is subevents in the next days
        if(genre.node.data.start_datetime_subevent_next_days !== null) 
          return ( 
            <li key={genre.node.id}>
              <Link to={`/${genre.node.data.url_slug_genre}`}>
                {genre.node.data.genre_name}
              </Link>
            </li> 
          )
        })}
    </ul>
    </AllEventLinks>
  )
};

export default AllGenresList;
